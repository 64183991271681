<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div class="bg-white rounded-lg p-4 w-full max-w-md md:mx-0 mx-4">
          <div>
            <div class="text-lg font-bold">{{ title }}</div>
            <div class="text-gray-500 font-medium mt-2">{{ message }}</div>
          </div>
          <div class="flex mt-6 justify-between">
            <button
              @click="cancel"
              class="bg-gray-200 hover:bg-gray-300 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg"
            >
              {{ buttonCancel }}
            </button>
            <button
              @click="confirm"
              class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg ml-4"
            >
              {{ button }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: "",
      message: "",
      button: "",
      buttonCancel: "",
    };
  },

  methods: {
    open({ title, message, button, buttonCancel }) {
      this.title = title ?? "No title provided";
      this.message = message ?? "No message provided";
      this.button = button ?? "OK";
      this.buttonCancel = buttonCancel ?? "Cancel";
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    confirm() {
      this.resolvePromise(true);
      this.dialog = false;
    },
    cancel() {
      this.resolvePromise(false);
      this.dialog = false;
    },
  },
};
</script>
